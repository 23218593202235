var  UserProfile = (function() {
    function getUrl() {
        return "https://db.scheffle.com"
    }

    return {
        getUrl: getUrl
    }
})();

export default UserProfile;