import React from 'react';
import './css/HomeComponent.css';

export class FooterComponent extends React.Component {

    render() {
        return (
        <div style={{height: '100px', backgroundColor: 'green', color: 'white', marginTop: '25px'}}>
            <div style={{}}>
                Scheffle
            </div>
        </div>)
    }

}